import React from "react";

const Shows = () => {
    return (
        <div className="shows-full">
            <h1>
                Catch us live this summer in the Midwest!
            </h1>
        </div>
    );
};

export default Shows;
